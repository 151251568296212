import React from "react";
import { Col } from "react-bootstrap";
import { ImageEmpty } from '../helper/utils';

export default function DrawB90row({ cardRow, ballHistory, cardsBelongToThisDraw }) {
  // console.log("cardRow", cardRow);
  return (
    <>
      {cardRow.map((cellValue, id) => {
        return (
          <Col
            key={id}
            className={
              (cardsBelongToThisDraw && ballHistory.indexOf(cellValue) >= 0 ? "shape-1 " : "") +
              (cellValue <= 0 ? "emptyCell" : "numberCell")
            }
          >
            {cellValue <= 0 ? <ImageEmpty /> : cellValue}
          </Col>
        );
      })}
    </>
  );
}
