import React from "react";
import { Row } from "react-bootstrap";

const DrawTitle = ({ number }) => {
  const titles = { 1: "B", 16: "I", 31: "N", 46: "G", 61: "O" };
  const cellName = `B${titles[number]}`;
  return (
    <div id={cellName} className={`tituloBalota ${cellName}`}>
      {titles[number]}
    </div>
  );
};

const DrawNumber = ({ number, ballHistory }) => {
  const ultimaBalota = ballHistory.length > 0 ? ballHistory[ballHistory.length -1] : -1;
  const ultimaClass = (ultimaBalota === number) ? "ultima" : "";
  const numberClass =
    ballHistory.indexOf(number) >= 0
      ? "marked" +
        (ballHistory.at(-1) === number
          ? " animate__animated animate__heartBeat"
          : "")
      : "";
  const cellName = `B${number}`;
  return (
    <div id={cellName} className={`balota ${cellName} ${numberClass} ${ultimaClass}`}>
      {number}
    </div>
  );
};

const GetGameCols = (gameType) => (gameType === 90 ? 10 : 15);
const GetGameRows = (gameType) => (gameType === 90 ? 9 : 5);

const HelperBoardRow = ({ gameType, curNumber, ballHistory }) => {
  const cols = GetGameCols(gameType);
  const rowData = [];
  for (let i = 1; i <= cols; i++) {
    if (
      gameType === 75 &&
      [1, 16, 31, 46, 61].indexOf(curNumber.current) !== -1
    )
      rowData.push(
        <DrawTitle
          key={`Title${curNumber.current}`}
          number={curNumber.current}
        />
      );

    rowData.push(
      <DrawNumber
        number={curNumber.current}
        key={curNumber.current}
        ballHistory={ballHistory}
      />
    );
    curNumber.current++;
  }
  return <div className="helperRow">{rowData}</div>;
};

const HelperTable = ({ gameType, ballHistory }) => {
  let curNumber = { current: 1 };
  const rows = GetGameRows(gameType);
  const tableData = [];
  for (let i = 1; i <= rows; i++)
    tableData.push(
      <HelperBoardRow
        key={`row${i}`}
        gameType={gameType}
        curNumber={curNumber}
        ballHistory={ballHistory}
      />
    );
  return tableData;
};

export default function HelperBoard({ gameType, ballHistory }) {
  if (!ballHistory) return;
  return (
    <div id="helperBoard" className={`bingo${gameType}`}>
      <Row>
        <HelperTable gameType={gameType} ballHistory={ballHistory} />
      </Row>
    </div>
  );
}
