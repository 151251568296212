import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DrawB90row from "./drawB90row";

export default function DrawB75card({
  cardSerie,
  cardData,
  ballHistory,
  cardsBelongToThisDraw,
}) {
  const { t } = useTranslation();

  const drawTitleLetter = (letter, indexTitle) => {
    return (
      <Col key={indexTitle} className="letterCell text-center">
        {letter}
      </Col>
    );
  };
  return (
    <Col xs={12} sm={6} md={4} lg={3} className="gameCard px-2 my-2">
      <Row className="gameCardTitle">
        <Col className="cardTitle text-end cardID">
          {t("id")}: {cardData.id}
        </Col>
      </Row>
      <Row className="gameCardLetters">
        {["B", "I", "N", "G", "O"].map((letter, i) =>
          drawTitleLetter(letter, i)
        )}
      </Row>
      {cardData.numbers.map((cardRow, id) => (
        <Row
          className="gameCardNumbers"
          key={`cardRow-${cardSerie}${cardData.id}${id}`}
        >
          <DrawB90row
            cardRow={cardRow}
            ballHistory={ballHistory}
            cardsBelongToThisDraw={cardsBelongToThisDraw}
          />
        </Row>
      ))}
    </Col>
  );
}
