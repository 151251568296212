import React from "react";
import { Row, Col, ProgressBar, Placeholder } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function InitialLoadingData({ errorFound }) {
  const { t } = useTranslation();
  return (
    <Row className="mt-5 pt-5">
      <Col />
      <Col xs={6} className="mt-5 pt-5">
        {!errorFound ? t("loading") : t("errorLoadingData")}
        {!errorFound ? (
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        ) : (
          <ProgressBar variant="danger" now={100} />
        )}
      </Col>
      <Col />
    </Row>
  );
}
