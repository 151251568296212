import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

const GetNumberRange = (number) => {
  if (number >= 1 && number <= 10) return "01_10";
  else if (number >= 11 && number <= 20) return "11_20";
  else if (number >= 21 && number <= 30) return "21_30";
  else if (number >= 31 && number <= 40) return "31_40";
  else if (number >= 41 && number <= 50) return "41_50";
  else if (number >= 51 && number <= 60) return "51_60";
  else if (number >= 61 && number <= 70) return "61_70";
  else if (number >= 71 && number <= 80) return "71_80";
  else if (number >= 81 && number <= 90) return "81_90";
}

const CurrentBall = ({ ballHistory, cardsBelongToThisDraw }) => {
  //const {gameLanguage, gameCountry} = bingoSettings
  const {t} = useTranslation()
  const curBall = (ballHistory.length > 0) ? ballHistory.at(-1) : '';

  // /* Cargar archivos de locución de números */
  // const toneSpacing = 2
  // const audioSprite = {}
  // for (let n = 0; n < 90; n++)
  //   audioSprite[`numero-${n+1}`] = [n * (toneSpacing * 1000), (toneSpacing * 1000)]

  // const soundUrl = `./sounds/numbers/${gameLanguage.toLowerCase()}-${gameCountry.toLowerCase()}/tono-1.mp3`;
  // console.log("cargando audio", soundUrl)
  // const [play] = useSound(soundUrl, { sprite: audioSprite });
  // console.log({ id: `numero-${curBall}` })
  // play({ id: `numero-${curBall}` })

  useEffect(() => {
    if (curBall !== ''){
      const curBallContainer = document.getElementById(`ball-${curBall}`);
      const numberRange = GetNumberRange(curBall);
      const baseClass = `currentBall R${numberRange}`
      if (curBallContainer){
        curBallContainer.className = `${baseClass} animate__animated animate__zoomInDown`
        setTimeout(() => curBallContainer.className = `${baseClass} animate__animated animate__zoomOutDown`, 1500);
        //setTimeout(() => curBallContainer.className = `${baseClass} animate__animated animate__zoomOutLeft`, 1500);
      }
    }
  }, [curBall]);

  if (curBall === '' || !cardsBelongToThisDraw){
    return (
      <div
        className='defaultBall'>
          {t("waiting")}
      </div>
    )
  }else{
    return (
      <div
        id={`ball-${curBall}`}
      >{curBall}</div>
    )
  }
}

const PlayedBalls = ({ballHistory}) => {
  const {t} = useTranslation()
  return (
    <div id="playedBalls">
      {t("ballsPlayed")}: {ballHistory.length}
    </div>
  );
}

export default function ballsPlayed({ballHistory, cardsBelongToThisDraw}) {
  if (!ballHistory) return
  return (
    <div>
      <CurrentBall
        ballHistory={ballHistory}
        cardsBelongToThisDraw={cardsBelongToThisDraw}
      />
      <PlayedBalls
        ballHistory={ballHistory}
        cardsBelongToThisDraw={cardsBelongToThisDraw}
      />
    </div>
  )
}