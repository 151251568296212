/* eslint-disable no-useless-escape */
import axios from 'axios';
import { showError } from './utils';
import NetworkError from './errors';

const instance = axios.create();

export const Get = async (url, headers = {}) => {
  try {
    const res = await instance.get(url, {
      headers: { ...headers },
    });
    if (res.data.error || !res.data)
      return showError(res.data.message)
    return res.data;
  } catch (err) {
    console.error("AxiosGET",err);
    throw new NetworkError();
  }
};

export const Post = async (url, data = {}, headers = {}) => {
  try {
    const res = await instance.post(url, data, {
      headers: { ...headers },
    });
    if (res.data.error || !res.data)
      return showError(res.data.message)
    return res.data;
  } catch (err) {
    console.error("AxiosPOST",err);
    throw new NetworkError();
  }
};

/*export const PostBody = async (url, body = {}) => {
  try {
    const res = await instance.post(url, body);
    if (res.data.TrnMessage) {
      if (parseInt(res.data.TrnMessage.split('-')[0], 10) === 9999) {
        setCurrentUser();
        document.location.href = '/';
      }
    }
    return res.data;
  } catch (err) {
    console.log(err);
    throw new NetworkError();
  }
};*/

const fetcher = {
  Post,
  Get
};

export default fetcher