import React from "react";
import { Row, Col } from "react-bootstrap";
import { bingoSettings } from "../config/settings";
import { useTranslation } from "react-i18next";

export default function GameTitle({
  playerData,
  socketDataReceived,
  cardsBelongToThisDraw,
}) {
  const { t } = useTranslation();
  const prizeTitleIndex = cardsBelongToThisDraw
    ? socketDataReceived && typeof socketDataReceived?.draw != "undefined"
      ? socketDataReceived.currentPrizeDraw
      : playerData.currentPrizeDraw
    : -1;
  // console.log("cardsBelongToThisDraw", cardsBelongToThisDraw);
  const prizeTitle =
    window.$playerData?.prizesList &&
    window.$playerData?.prizesList[prizeTitleIndex]
      ? window.$playerData.prizesList[prizeTitleIndex]
      : window.$playerData?.prizesGlobalDescription
      ? window.$playerData.prizesGlobalDescription
      : "No existe";
  return (<>
    <Row id="gameTitleRow">
      <Col id="gameLogo">
        <img alt="Logo" src={bingoSettings.gameLogo} className="img-fluid" />
      </Col>
      <Col xs={6}>
        <Row>
          <Col xs={12} id="gameTitle" className="fs-3">
            {t("prize")}
          </Col>
        </Row>
        <Row>
          <Col xs={12} id="prizeTitle" className="fs-">
            {prizeTitle}
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col id="playerName">{playerData.playerName}</Col>
    </Row>
  </>);
}
