import React, { useState } from "react";
import DrawSeries from "./drawSeries";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function GameCards({
  playerData,
  ballHistory,
  socketDataReceived,
  cardsBelongToThisDraw,
}) {
  const [curPage, setCurPage] = useState(0);
  const { t } = useTranslation();
  const { gameCards, gameType, prizesList } = playerData;
  const currentPrizeDraw =
    typeof socketDataReceived.currentPrizeDraw != "undefined"
      ? socketDataReceived.currentPrizeDraw
      : playerData.currentPrizeDraw;
  if (!gameCards) return;

  let cardsAmount = 0;
  // console.log("cardsBelongToThisDraw", cardsBelongToThisDraw);
  // console.log("gameCards", gameCards);
  // console.log("gameCards->playerData", playerData);
  console.log("gameCards->socketDataReceived", socketDataReceived);
  // console.log("gameCards->ballHistory", ballHistory);
  const itemsPerPage = gameType === 90 ? 6 : 8;
  const Cards = [];
  if (gameCards) {
    gameCards.map(
      (seriesData, index) => (cardsAmount += seriesData.cards.length)
    );
    window.$cardsCounter = 0;
    gameCards.map((seriesData, index) => {
       // console.log("gameCards", seriesData, seriesData.cards.length);
      Cards.push(
        <DrawSeries
          key={`card-${index}`}
          seriesData={seriesData}
          seriesIndex={index}
          itemsPerPage={itemsPerPage}
          cardsAmount={cardsAmount}
          curPage={curPage}
          ballHistory={ballHistory}
          gameType={gameType}
          cardsBelongToThisDraw={cardsBelongToThisDraw}
        />
      );
      // console.log("cardsAmount", cardsAmount);
      return Cards;
    });
  }

  const totalPages = () => {
    return Math.ceil(cardsAmount / itemsPerPage);
  }

  const lastPage = () => {
    if (curPage > 0) setCurPage(curPage - 1);
  };

  const nextPage = () => {
    if (curPage < totalPages() - 1) setCurPage(curPage + 1);
  };

  const juegoTitle = cardsBelongToThisDraw
    ? currentPrizeDraw === -1
      ? ``
      : `${t("draw")} ${currentPrizeDraw + 1}/${prizesList.length}`
    : t("cardsDoNotBelongToThisDraw");

  const firstPageClass = () => {
    return (curPage > 0) ? "back" : "back pageDisabled";
  }

  const lastPageClass = () => {
    console.log("totalpages; ", totalPages());
    return (curPage < totalPages() - 1) ? "next" : "next pageDisabled";
  }

  return (
    <Col className="seriesContainer">
      <Row>
        <Col className="px-4 pt-2">
          {t("cards")}: {cardsAmount}
        </Col>
        <Col className="text-end px-4 pt-2">{juegoTitle}</Col>
      </Row>
      {Cards}
      <Row>
        <Col className="text-center">
          <button type="button" className={firstPageClass()} onClick={lastPage}>
            &lt;
          </button>
          {curPage + 1} / {totalPages()}
          <button type="button" className={lastPageClass()} onClick={nextPage}>
            &gt;
          </button>
        </Col>
      </Row>
    </Col>
  );
}
