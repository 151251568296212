export const bingoSettings = {

  defaultToken: "",
  apiTarget: {
    "localhost:3000": "http://localhost/Dropbox/bingo/plataforma/api/public/",
    "sandbox": "https://sandbox-bingo-api.tiobingo.com/public/",
    "game-api": "https://game-api.tiobingo.com/public/"
  },
  apiURL: "https://game-api.tiobingo.com/public/",
  getCardsByTokenEndpoint: "player/getCardsByToken.php?f=true&token=",
  getCardsByPhoneEndpoint: "player/getCards.php?f=true&phone=",
  gameLogo: "images/logo.png",
  gameLanguage: "es",
  gameCountry: "co",

  s3Endpoint: "https://si-al-bingo.s3.amazonaws.com/",

  getDrawsEndpoint: "game/getDraws.php",
  socketRetrySeconds: 2000,
  socketMaxConsecutiveRetries: 50,
  initCustomerBoardOnConnection: false,
}