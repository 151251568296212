import React from "react";
import { Row } from "react-bootstrap";
import DrawB90card from "./drawB90card";
import DrawB75card from "./drawB75card";

export default function DrawSeries(params) {
  //console.log("DrawSeries param", params);
  const cardSerie = params.seriesData.series;
  const seriesCount = params.seriesData.cards.length;
  // Para el ejemplo:
  //  cardsAmount: 34  itemsPerPage: 8
  // empiezo a numerar cada card que se va a mostrar
  const Cards = [];
  params.seriesData.cards.map((cardData, cardIndex) => {
    // console.log("cardCounter:", window.$cardsCounter, cardData.id);
    if (
      window.$cardsCounter >= params.curPage * params.itemsPerPage &&
      window.$cardsCounter < (params.curPage + 1) * params.itemsPerPage
    ) {
      if (params.gameType === 90){
        Cards.push(
          <DrawB90card
            key={`${cardData.id}${window.$cardsCounter}`}
            cardSerie={cardSerie}
            cardData={cardData}
            ballHistory={params.ballHistory}
            cardsBelongToThisDraw={params.cardsBelongToThisDraw}
          />
        );
      }else{
        Cards.push(
          <DrawB75card
            key={`${cardData.id}${window.$cardsCounter}`}
            cardSerie={cardSerie}
            cardData={cardData}
            ballHistory={params.ballHistory}
            cardsBelongToThisDraw={params.cardsBelongToThisDraw}
          />
        );
      }
    }
    window.$cardsCounter++;
    return Cards;
  });
  if (params.gameType === 90)
    return <Row className="gameCardContainer">{Cards}</Row>
  else
    return <>{Cards}</>
    // return <div className="game75CardContainer col col-sm-6 col-md-3 px-2 my-2">{Cards}</div>
}
