import React, { useCallback, useState } from "react";
import BallsPlayed from "./ballsPlayed";
import HelperBoard from "./helperBoard";
import GameTitle from "./gameTitle";
import GameCards from "./gameCards";
import { Row, Col } from "react-bootstrap";
import InitialLoadingData from "./initialLoadingData";
import { bingoSettings } from "../config/settings";
import useWebSocket from "react-use-websocket"; // https://github.com/robtaussig/react-use-websocket
import {
  getURLparameter,
  ImageGanadorPropio,
  ImageGanadorOtro,
  getS3endpoint,
} from "../helper/utils";
import { Get } from "../helper/fetchData";
import ConfettiExplosion from "react-confetti-explosion";
import useSound from "use-sound";

export default function GameBoard() {
  const [cardsBelongToThisDraw, setCardsBelongToThisDraw] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [errorFound, setErrorfound] = useState(false);
  const [playerData, setPlayerData] = useState({});
  const [ballHistory, setBallHistory] = useState([]);
  const [socketDataReceived, setSocketDataReceived] = useState({});
  const [isExploding, setIsExploding] = React.useState(0);
  const [sonoGanador, setSonoGanador] = React.useState(false);
  const { gameLanguage, gameCountry } = bingoSettings;
  const WS_OPCODE_PING = "9";
  const WS_OPCODE_PONG = "10";

  /* Cargar archivos de locución de números */
  const toneSpacing = 2;
  const audioSprite = {};
  for (let n = 0; n < 90; n++)
    audioSprite[`numero-${n + 1}`] = [
      n * (toneSpacing * 1000),
      toneSpacing * 1000,
    ];

  const audioNumeros = `${getS3endpoint()}assets/sound/numbers/${gameLanguage.toLowerCase()}-${gameCountry.toLowerCase()}/tono-1.mp3`;
  // console.log("cargando audio", audioNumeros);
  const [cantaNumero] = useSound(audioNumeros, { sprite: audioSprite });

  const audioGanadorPropio = `${getS3endpoint()}assets/sound/bingo.mp3`;
  const audioGanadorOtro = `${getS3endpoint()}assets/sound/salido_un_bingo.mp3`;
  const [cantaGanadorPropio] = useSound(audioGanadorPropio);
  const [cantaGanadorOtro] = useSound(audioGanadorOtro);

  const SayNumber = (curBall) => {
    // console.log({ id: `numero-${curBall}` });
    cantaNumero({ id: `numero-${curBall}` });
  };

  const setCardsBelonging = (objDataReceived) => {
    // console.log(
    //   "Esta buscando si el objeto recibido pertenece al juego del cliente",
    //   parseInt(window.$playerData.draw),
    //   parseInt(objDataReceived.draw)
    // );

    let drawPlayingIsUserDraw = true;
    if (window.$playerData.draw && objDataReceived.draw) {
      drawPlayingIsUserDraw =
        parseInt(window.$playerData.draw) === parseInt(objDataReceived.draw);
      setCardsBelongToThisDraw(drawPlayingIsUserDraw);
    }
  };

  const getSocketUrl = useCallback(() => {
    console.log("Ejecutando getSocketUrl  -> useCallback");
    return new Promise((resolve) => {
      const token = getURLparameter("token") || null;
      if (token !== null) {
        console.log(
          `Se resolverá la promesa obteniendo los cartones del usuario con el token: ${token}`
        );
        getPlayerCards(token).then((wsURL) => resolve(wsURL));
      } else {
        const phone = getURLparameter("phone") || null;
        console.log(
          `Se resolverá la promesa obteniendo los cartones del usuario con el teléfono: ${phone}`
        );
        getPlayerCardsByPhone(phone).then((wsURL) => resolve(wsURL));
      }
    });
  }, []);

  const isPlayerWinner = (objDataReceived) => {
    if (!objDataReceived?.winnerFound) return;
    // Bingo: {"action":"markBall","draw":"135","ballNumber":22,"ballHistory":[38,22],"winnerFound":true,"currentPrizeDraw":0,"winnerCards":[{"id":"SZB312","series":"0135-000002","numbers":[[9,-1,22,-1,-1,54,-1,70,81],[-1,16,-1,30,43,-1,66,78,-1],[-1,-1,23,38,-1,58,67,-1,88]]}],"gameEnded":false,"proximityWinnerCards":[{"id":"ZWL567","series":"0135-000006","numbers":[[-1,12,-1,36,45,-1,60,71,-1],[-1,17,22,-1,-1,50,-1,73,81],[3,-1,29,-1,48,53,-1,-1,86]]}],"proximityWinnerPhoneNumbers":["3003248657"]}
    //   winnerCards":[{"id":"SZB312","series":"0135-000002","numbers":[[9,-1,22,-1,-1,54,-1,70,81],[-1,16,-1,30,43,-1,66,78,-1],[-1,-1,23,38,-1,58,67,-1,88]]}]
    const playerCards = window.$playerData.gameCards;
    const playerWinnerCards = playerCards
      .map((v) =>
        v.cards
          .map((c) => c.id)
          .filter((playerCard) =>
            objDataReceived.winnerCards.map((v) => v.id).includes(playerCard)
          )
      )
      .filter((finalCards) => (finalCards.length > 0 ? finalCards[0] : false));
    const tipoGanador = playerWinnerCards.length > 0 ? 1 : 2;
    setIsExploding(tipoGanador);
    setSonoGanador(true);
    if (!sonoGanador)
      if (tipoGanador === 1) cantaGanadorPropio();
      else cantaGanadorOtro();
  };

  const messageManager = (objDataReceived) => {
    setCardsBelonging(objDataReceived);
    setIsExploding(0);
    setSonoGanador(false);
    switch (objDataReceived.action) {
      case "reset":
        // Primer envío {"action":"reset","draw":"135","currentPrizeDraw":0}
        setSocketDataReceived(objDataReceived);
        setBallHistory([]);
        console.log("Esta reseteando el juego");
        break;
      case "markBall":
        // Balota: {"action":"markBall","draw":"135","ballNumber":1,"ballHistory":[1],"winnerFound":false,"currentPrizeDraw":0}
        // Bingo: {"action":"markBall","draw":"135","ballNumber":22,"ballHistory":[1,2,3,16,31,46,65,82,34,21,4,5,6,7,8,9,10,90,89,88,87,86,85,84,83,81,11,41,51,61,71,80,50,70,40,30,20,60,19,28,37,55,64,73,12,23,45,56,77,67,78,66,36,35,74,15,24,27,68,63,42,43,44,47,48,49,52,53,54,57,58,59,33,38,22],"winnerFound":true,"currentPrizeDraw":0,"winnerCards":[{"id":"SZB312","series":"0135-000002","numbers":[[9,-1,22,-1,-1,54,-1,70,81],[-1,16,-1,30,43,-1,66,78,-1],[-1,-1,23,38,-1,58,67,-1,88]]}],"gameEnded":false,"proximityWinnerCards":[{"id":"ZWL567","series":"0135-000006","numbers":[[-1,12,-1,36,45,-1,60,71,-1],[-1,17,22,-1,-1,50,-1,73,81],[3,-1,29,-1,48,53,-1,-1,86]]}],"proximityWinnerPhoneNumbers":["3003248657"]}

        if (cardsBelongToThisDraw) {
          setSocketDataReceived(objDataReceived);
          if (objDataReceived.ballHistory)
            setBallHistory(objDataReceived.ballHistory);
          console.log("gameBoard->playerData", playerData);
          console.log("gameBoard->socketDataReceived", objDataReceived);
          isPlayerWinner(objDataReceived);
          SayNumber(objDataReceived.ballNumber);
        }
        // https://codesandbox.io/s/11tjr
        // https://dev.to/tsparticles/how-to-create-beautiful-and-realistic-confetti-animations-in-react-using-tsparticles-3j24
        break;
      case "end":
        // Fin: {"action":"end","draw":"135","currentPrizeDraw":0}
        setSocketDataReceived(objDataReceived);
        setBallHistory([]);
        console.log("Esta finalizando el juego");
        break;
      default:
        console.log("Default Case");
        break;
    }
  };

  // eslint-disable-next-line
  const {
    sendMessage,
    /*,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,*/
  } = useWebSocket(getSocketUrl, {
    onOpen: () => {
      // let socketRequest = {action: 'inicializaTableroCliente' }
      // bingoSocketManager.send('message', JSON.stringify(socketRequest))
      console.log("Socket connection established...");
    },
    onMessage: (message) => {
      console.log("Ejecutando onMessage ");
      if (message.data && message.data === WS_OPCODE_PING) {
        //console.log("Se detectó un PING, generando PONG ")
        return sendMessage(WS_OPCODE_PONG);
      } else {
        if (message.data) {
          console.log("Socket message received: ", message);
          const objDataReceived = JSON.parse(message.data);
          messageManager(objDataReceived);
        }
      }
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  const getEndPointURL = () => {
    setIsLoadingData(true);
    const locationHref = window.location.href.split("/");
    const urlHref = locationHref[2].split(".");
    var apiEndpoint = bingoSettings.apiURL;
    if (typeof bingoSettings.apiTarget[urlHref[0]] !== "undefined")
      apiEndpoint = bingoSettings.apiTarget[urlHref[0]];
    return apiEndpoint;
  };

  const processPlayerCardsResponse = (playerDataResponse) => {
    console.log("axios playerDataResponse", playerDataResponse);
    if (!playerDataResponse || !playerDataResponse.playerName)
      setErrorfound(true);
    else setIsLoadingData(false);

    window.$playerData = playerDataResponse;
    setPlayerData(playerDataResponse);
    if (playerDataResponse && playerDataResponse.markedBalls)
      setBallHistory(playerDataResponse.markedBalls);
  };

  const processPlayerCardsError = (error) => {
    console.error("getPlayerCards-Error", error);
    setErrorfound(true);
  };

  const getPlayerCards = async (userToken) => {
    return new Promise((resolve, reject) => {
      try {
        const apiEndpoint = getEndPointURL();
        Get(
          `${apiEndpoint}${bingoSettings.getCardsByTokenEndpoint}${userToken}`,
          {}
        ).then((playerDataResponse) => {
          processPlayerCardsResponse(playerDataResponse);
          if (playerDataResponse && playerDataResponse.websocketUrl)
            resolve(playerDataResponse.websocketUrl);
        });
      } catch (error) {
        processPlayerCardsError(error);
        reject(error);
      }
    });
  };

  const getPlayerCardsByPhone = async (userPhone) => {
    return new Promise((resolve, reject) => {
      try {
        const apiEndpoint = getEndPointURL();
        Get(
          `${apiEndpoint}${bingoSettings.getCardsByPhoneEndpoint}${userPhone}`,
          {}
        ).then((playerDataResponse) => {
          processPlayerCardsResponse(playerDataResponse);
          if (playerDataResponse && playerDataResponse.websocketUrl)
            resolve(playerDataResponse.websocketUrl);
        });
      } catch (error) {
        processPlayerCardsError(error);
        reject(error);
      }
    });
  };

  const container = {
    position: "absolute",
    overflow: "auto",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "#29313d",
  };

  const source = {
    position: "absolute",
    right: "50%",
    left: "50%",
    top: "50%",
  };

  const bigExplodeProps = {
    force: 0.8,
    duration: 10000,
    particleCount: 250,
    height: 1600,
    width: 1600,
  };

  if (isLoadingData) {
    return <InitialLoadingData errorFound={errorFound} />;
  } else {
    const userBallHistory = cardsBelongToThisDraw
      ? ballHistory
      : playerData.markedBalls;
    return (
      <Row id="gameContainer" style={container}>
        <ImageGanadorPropio display={isExploding} />
        <ImageGanadorOtro display={isExploding} />
        <Col xs={12}>
          <GameTitle
            playerData={playerData}
            socketDataReceived={socketDataReceived}
            cardsBelongToThisDraw={cardsBelongToThisDraw}
          />
        </Col>
        <Col xs={12} sm={4} id="gameDataContainer">
          <BallsPlayed
            ballHistory={userBallHistory}
            socketDataReceived={socketDataReceived}
            cardsBelongToThisDraw={cardsBelongToThisDraw}
          />
          {isExploding === 1 && (
            <div style={source}>
              <ConfettiExplosion {...bigExplodeProps} />
            </div>
          )}
          <HelperBoard
            ballHistory={userBallHistory}
            gameType={playerData.gameType}
            socketDataReceived={socketDataReceived}
            cardsBelongToThisDraw={cardsBelongToThisDraw}
          />
        </Col>
        <Col xs={12} sm={8} id="cardsContainer">
          <GameCards
            playerData={playerData}
            ballHistory={userBallHistory}
            socketDataReceived={socketDataReceived}
            cardsBelongToThisDraw={cardsBelongToThisDraw}
          />
        </Col>
      </Row>
    );
  }
}
