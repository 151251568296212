import {createRoot} from "react-dom/client"
import 'bootstrap/dist/css/bootstrap.min.css'
import './dist/scss/bingo-play.scss'
import React from 'react'
import GameBoard from './components/gameBoard'
import "./i18n"

window.$playerData = {}
window.$cardsCounter = 0;

const root = createRoot(document.getElementById("root"))
root.render(
  <React.Suspense fallback="Loading...">
    <GameBoard />
  </React.Suspense>
)