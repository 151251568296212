import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { bingoSettings } from "../config/settings";

const MySwal = withReactContent(Swal);

export const formatNumber = (numberString, accuracy = 2) => {
  if (numberString == null) {
    return "no registra";
  }
  const nStr = numberString.toString();
  const x = nStr.split(".");
  let x1 = x[0];
  const baseAccuracy = "0".repeat(accuracy);
  const decimalData = `${x[1]}${baseAccuracy}`;
  const x2 =
    x.length > 1
      ? `.${decimalData.substring(0, accuracy)}`
      : `.${baseAccuracy}`;
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1,$2`);
  }
  return x1 + x2;
};

export const showSuccess = async (message, title = "", options = {}) => {
  Alert(message, title, "success", (options = {}));
};

export const showError = async (message, title = "", options = {}) => {
  Alert(message, title, "error", (options = {}));
};

export const showWarning = async (message, title = "", options = {}) => {
  Alert(message, title, "warning", (options = {}));
};

export const Alert = async (
  message,
  title = "Atención",
  type = "warning",
  options = {}
) => {
  const defaults = {
    title,
    html: message,
    icon: type,
    //confirmButtonColor: '#3085d6',
    confirmButtonText: "Aceptar",
    ...options,
  };
  return MySwal.fire(defaults);
};

export const Confirm = async (
  message,
  title = "Atención",
  type = "info",
  options = {}
) => {
  const defaults = {
    title,
    html: message,
    icon: type,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Continuar",
    cancelButtonText: "Cancelar",
    ...options,
  };
  return MySwal.fire(defaults);
};

export const getRandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getURLparameter = (parameterName) => {
  const search = window.location.search;
  return new URLSearchParams(search).get(parameterName);
};

export const getS3endpoint = () => {
  return bingoSettings.s3Endpoint;
};

export const ImageEmpty = () => {
  return (
    <img
      src={`${getS3endpoint()}assets/img/empty-cell.png`}
      className="img-fluid"
      alt="Empty Cell"
    />
  );
};

export const ImageGanadorPropio = ({ display }) => {
  const hiddenClass = display === 1 ? "" : "d-none";
  return (
    <img
      src={`${getS3endpoint()}assets/img/bingo.png`}
      className={`img-fluid winnerImage ${hiddenClass}`}
      alt="Bingo!"
    />
  );
};

export const ImageGanadorOtro = ({ display }) => {
  const hiddenClass = display === 2 ? "" : "d-none";
  return (
    <img
      src={`${getS3endpoint()}assets/img/bingo-otro.png`}
      className={`img-fluid winnerImage ${hiddenClass}`}
      alt="Bingo!"
    />
  );
};
